import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { InterractiveSection } from "@chweb/commonui";

import SectionSocial from '../components/section-social';

import { labelsByLocale } from '../components/locale-context';
import { pageInfoByLocale } from '../queries/page-info-helpers';
import { getLocalizedValue } from '../components/locale-context';
import { ContactEmail } from '../components/email';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { PageInfoData } from '../queries/fragment-page-info';
import { AllLocalesLabels } from '../queries/fragment-all-locales-labels';

interface AwardsListProps {
  data: PageContactData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function Contacts ({ data, pageContext, location }: AwardsListProps): ReactElement {
  const locale = pageContext.locale;
  const labels = labelsByLocale(data.labels.nodes)[locale];

  const { address, email, telephone } = data.md.frontmatter;
  const localAddressMatch = address.locales.find(l => l.locale === locale);
  const localAddress = localAddressMatch ? localAddressMatch.address : '';
  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);
  const seo = PageSEO.fromLocation(location);
  seo.description = localPageInfo.seoDescription;

  return (
    <Layout locale={locale} location={location} title = { localPageInfo.title } seo = { seo }>
      <InterractiveSection title = { localPageInfo.title } >
        <div className = "w3-container">
          <div>
            <h3>{ labels.contactSocialMedia }</h3>
            <SectionSocial />
          </div>
          <div>
            <h3>{ labels.contactMailPhone }</h3>
            <p>{ localAddress }</p>
            <p>{ labels.contactPhone } { telephone }</p>
          </div>
          <div>
            <h3>{ labels.contactEmail }</h3>
            <ContactEmail eaddr = { email } />
          </div>
        </div>
      </InterractiveSection>
    </Layout>
  );
}

interface PageContactData {
  md: {
    frontmatter: {
      email: string,
      telephone: string,
      address: {
        locales: {
          locale: string,
          address: string
        }[],
      }
    }
  },
  page: PageInfoData,
  labels: {
    nodes: AllLocalesLabels[]
  }
}

export const query = graphql`
{
  md: markdownRemark(fields: {slug: {regex: "/contact/"}}) {
    frontmatter {
      email
      telephone
      address {
        locales {
          locale
          address
        }
      }
    }
  }
  page: pageIndexYaml(yamlId : {eq: "contact"}) {
    ...PageInfo
  }
  labels: allLabelsTranslatedYaml(filter: {name: {in: [
      "contactSocialMedia",
      "contactMailPhone",
      "contactPhone",
      "contactEmail"
  ]}}) {
    nodes {
      ...AllLocalesLabels
    }
  }
}
`;
